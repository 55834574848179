<template>
  <div id="user-view">
    <v-tabs
      v-model="accountTab"
      show-arrows
      class="user-tabs"
    >
      <v-tab v-if="subscribed('Billing Management')">
        <span>Income</span>
      </v-tab>

      <v-tab v-if="subscribed('Expenses')">
        <span>Expenses</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items
      id="user-tabs-content"
      v-model="accountTab"
      class="mt-5 pa-1"
      touchless
    >
      <v-tab-item v-if="subscribed('Billing Management')">
        <income-accounts></income-accounts>
      </v-tab-item>

      <v-tab-item v-if="subscribed('Expenses')">
        <expenses-accounts></expenses-accounts>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  ref,
  inject,
} from '@vue/composition-api'
import IncomeAccounts from './IncomeAccounts.vue'
import ExpensesAccounts from './ExpensesAccounts.vue'

export default {
  components: { IncomeAccounts, ExpensesAccounts },
  setup() {
    // Initializers
    const subscribed = inject('subscribed')

    // Properties
    const accountTab = ref(null)

    return {
      subscribed,
      accountTab,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
