<template>
  <v-row>
    <v-col
      v-for="account in incomeAccounts"
      :key="account.id"
      cols="12"
      sm="6"
      md="4"
      class="accounts-items"
    >
      <v-card>
        <v-card-title class="py-3">
          {{ account.name }}
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              size="30"
              color="white"
              @click="isSaveCategoryDialogOpen = true; categoryFormTab = 'blank'; fetchCategoryGroup(account.id)"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="account.categories.length > 0"
          class="py-0"
        >
          <v-list
            subheader
            two-line
            class="pb-0"
          >
            <v-list-item
              v-for="category in account.categories"
              :key="category.id"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title v-text="category.name"></v-list-item-title>
                <p class="mb-0 mt-2 pl-5">
                  {{ category.description }}
                </p>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="fetchCategory(category.id); fetchCategoryGroup(account.id); isSaveCategoryDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span>Edit</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="fetchCategory(category.id); isDeleteCategoryDialogOpen = true">
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>Delete</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text
          v-else
          class="text-center"
        >
          <h2 class="my-4">
            No categories yet!
          </h2>
          <p class="mb-0">
            Click on the + icon on the top right to add a new category into this income group
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Save dialog -->
    <v-dialog
      v-model="isSaveCategoryDialogOpen"
      width="650"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          {{ category.id ? 'Edit' : 'New' }} category
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isSaveCategoryDialogOpen = false; categoryFormTab = 'blank'; category = {}"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-7">
          {{ account.description }}
        </v-card-text>

        <v-card-text v-if="!category.id">
          <v-row>
            <v-col>
              <v-btn
                block
                depressed
                :outlined="categoryFormTab === 'template'"
                color="primary"
                @click="categoryFormTab = 'blank'"
              >
                Manual entry
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                depressed
                :outlined="categoryFormTab === 'blank'"
                color="primary"
                @click="categoryFormTab = 'template'"
              >
                Premade templates
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="categoryFormTab == 'blank'">
          <p>
            You can add new categories under this income group.
          </p>
          <v-form
            ref="saveForm"
            v-model="saveValid"
          >
            <v-text-field
              v-model="category.name"
              outlined
              dense
              label="Name"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              :value="account.name"
              outlined
              dense
              disabled
              label="Category Group"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-textarea
              v-model="category.description"
              outlined
              rows="3"
              label="Description"
              hide-details="auto"
              class="mb-6"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-text v-if="categoryFormTab == 'template'">
          <template v-if="account.templates.length > 0">
            <p>
              We prepared some premade categorioes that you can add to your organization!
            </p>
            <v-list
              subheader
              two-line
              class="pb-0"
            >
              <v-list-item
                v-for="(template, index) in account.templates"
                :key="index"
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="template.name"></v-list-item-title>

                  <v-list-item-subtitle
                    class="flex-nowrap"
                    v-text="template.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    block
                    depressed
                    color="primary"
                    :loading="templateCreationLoading"
                    @click="createCategoryFromTemplate(template.name, template.description)"
                  >
                    Add
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
          <div
            v-else
            class="text-center"
          >
            <h2 class="mt-10 mb-3">
              No templates found!
            </h2>
            <p class="mb-8">
              The categories in this group are unique to individual businesses.<br>You should fill this part in yourself or request support from our team.
            </p>
          </div>
        </v-card-text>

        <v-card-actions v-if="categoryFormTab === 'blank'">
          <v-btn
            color="primary"
            block
            :loading="saveCategoryLoading"
            :disabled="saveCategoryLoading || !saveValid"
            @click="saveCategory()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog
      v-model="isDeleteCategoryDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Delete {{ category.name }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isDeleteCategoryDialogOpen = false; category = {}"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This category will be removed from this list. It will no longer appear in searches or available for use.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="deleteCategoryLoading"
            :disabled="deleteCategoryLoading"
            class="mt-3"
            @click="deleteCategory(category.id)"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const incomeAccounts = ref(null)
    const account = ref({
      name: null,
      templates: [],
    })
    const category = ref({})
    const isSaveCategoryDialogOpen = ref(false)
    const isDeleteCategoryDialogOpen = ref(false)
    const categoryFormTab = ref('blank')
    const templateCreationLoading = ref(false)
    const saveForm = ref(null)
    const saveCategoryLoading = ref(false)
    const saveValid = ref(false)
    const deleteCategoryLoading = ref(false)

    // Methods
    const fetchIncomeGroup = () => {
      store
        .dispatch('knowledgeBaseStore/fetchIncomeAccounts')
        .then(response => {
          incomeAccounts.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching income accounts. Please refresh!')
        })
    }
    const fetchCategoryGroup = groupId => {
      store
        .dispatch('knowledgeBaseStore/fetchCategoryGroup', { id: groupId })
        .then(response => {
          account.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching this income account. Please refresh!')
        })
    }
    const fetchCategory = id => {
      store
        .dispatch('clericalSettingsStore/fetchCategory', { id })
        .then(response => {
          category.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching category. Please refresh!')
        })
    }
    const saveCategory = () => {
      saveCategoryLoading.value = true
      store
        .dispatch(`clericalSettingsStore/${category.value.id ? 'updateCategory' : 'createCategory'}`, {
          id: category.value.id,
          name: category.value.name,
          description: category.value.description,
          category_group_id: account.value.id,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchIncomeGroup()
          isSaveCategoryDialogOpen.value = false
          saveCategoryLoading.value = false
          saveForm.value.resetValidation()
          category.value = {}
        })
        .catch(error => {
          saveCategoryLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding category. Please refresh!')
        })
    }
    const createCategoryFromTemplate = (name, description) => {
      templateCreationLoading.value = true
      store
        .dispatch('clericalSettingsStore/createCategory', { name, description, category_group_id: account.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          templateCreationLoading.value = false
          fetchIncomeGroup()
        })
        .catch(error => {
          templateCreationLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching this income account. Please refresh!')
        })
    }
    const deleteCategory = categoryId => {
      deleteCategoryLoading.value = true
      store
        .dispatch('clericalSettingsStore/deleteCategory', { id: categoryId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchIncomeGroup()
          isDeleteCategoryDialogOpen.value = false
          deleteCategoryLoading.value = false
          category.value = {}
        })
        .catch(error => {
          deleteCategoryLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting category. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchIncomeGroup()
    })

    return {
      // Properties
      incomeAccounts,
      account,
      category,
      isSaveCategoryDialogOpen,
      isDeleteCategoryDialogOpen,
      categoryFormTab,
      templateCreationLoading,
      saveForm,
      saveValid,
      saveCategoryLoading,
      deleteCategoryLoading,

      // Methods
      fetchCategoryGroup,
      fetchCategory,
      saveCategory,
      createCategoryFromTemplate,
      deleteCategory,

      // Others
      validators: {
        required,
      },
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.accounts-items {
  .theme--light.v-sheet .v-card__title {
    color: white !important;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
    max-height: calc(100vh - 250px) !important;

    .v-card__title {
      background-color: #019267 !important;
    }

    .v-card__text {
      flex-grow: 1;
      overflow: auto;
    }
  }

  .v-list-item__content > * {
    line-height: 1.5;
  }
}
</style>
